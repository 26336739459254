.welcome-container {
  background-image: url("/public//assets/bg_image.jpeg");
  min-height: 92vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 !important;
  max-width: 100% !important;
  padding: 24px 24px 0 24px;
}

.selector-icon {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}

.selector-icon:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
